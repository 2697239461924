import React, { useState, useEffect, cloneElement, useLayoutEffect, useRef } from "react";
import { db } from "../../configuration";
import { collection, getDocs } from "firebase/firestore";
import { motion } from "framer-motion";
import "./mansorygrid.css";

function MosaicList({ children, itemWidth }) {
    const [screenWidth] = useWindowSize();
    const columnCount = (screenWidth && Math.floor(screenWidth / 2 / itemWidth)) || 1;

    const columns = Array.from({ length: columnCount }, () => []);

    children.forEach((child, index) => {
        columns[index % columnCount].push(child);
    });

    return (
        <div className="mansory">
            {columns.map((column, colIndex) => (
                <div key={colIndex} style={{ flex: 1, display: "flex", flexDirection: "column", gap: "20px"}}>
                    {column.map((child, childIndex) => (
                        cloneElement(child, { key: `${colIndex}-${childIndex}` })
                    ))}
                </div>
            ))}
        </div>
    );
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

const MansoryGrid = ({ collectionName }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const itemRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, collectionName));
                const data = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setItems(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [collectionName]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <MosaicList itemWidth={200}>
            {items.map((item, index) => (
                <motion.div className="item-container" 
                ref={itemRef} 
                initial={{ opacity: 0, y: "30%" }}
                animate={{ opacity: 1, y: "0%" }}
                transition={{ duration: 0.5 }}
                >

                <div className="background-overlay"></div>
          
                <motion.div
                  key={index}
                  className="item"
                  whileHover={{
                    x: 5,
                    y: 5,
                  }}
                >
                  <div className="media-container">
                    <img
                      className="project-img"
                      alt={item.id || "Image"}
                      src={item.img || "https://via.placeholder.com/200"}
                    />
                    <div
                      className="text-on-image"
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      <img
                        style={{ filter: "blur(1px)", opacity: "0.4" }}
                        className="img-under-text"
                        alt={item.id || "Image"}
                        src={item.img || "https://via.placeholder.com/200"}
                      />
                      <p className="t">Visit {item.type}</p>
                    </div>
                  </div>
                  <div className="content">
                    <h3 className="name">{item.name || "Unnamed Item"}</h3>
                    <p className="detail">{item.detail || "No description available."}</p>
                  </div>
                </motion.div>
              </motion.div>
            ))}
        </MosaicList>
    );
};

export default MansoryGrid;
