// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAu4rpzW0ZSFt-5oyPZiytyi2B03x0pTA0",
  authDomain: "portfolio-3b6ae.firebaseapp.com",
  projectId: "portfolio-3b6ae",
  storageBucket: "portfolio-3b6ae.firebasestorage.app",
  messagingSenderId: "771253952926",
  appId: "1:771253952926:web:c56dc4809e6756e6aeb68f",
  measurementId: "G-JLL2ZNM9RX",
  databaseURL: "https://portfolio-3b6ae-default-rtdb.asia-southeast1.firebasedatabase.app"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {db};