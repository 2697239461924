import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import About from './pages/about/About'
import Home from './pages/home/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/about' element={<About/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
