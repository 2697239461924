import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion"; // Import AnimatePresence
import MansoryGrid from "../../components/mansory-grid/MansoryGrid";
import List from "../../components/list/List";
import "./home.css";

const Home = () => {
  const [activeCell, setActiveCell] = useState(null); // Tracks the active cell
  const [hoveredCell, setHoveredCell] = useState(null); // Tracks the hovered cell
  const [zIndex, setZIndex] = useState(null); // Tracks z-index status

  const tileNames = {
    "work-experience": "Work Experiences",
    projects: "Projects",
    education: "About",
    resume: "CV",
    contact: "Contact",
    skills: "Skills",
  };

  const handleCellClick = (name) => {
    if (name === "resume") {
      return;
    }

    if (activeCell === name) {
      setActiveCell(null);
      setTimeout(() => setZIndex(null), 1000);
    } else {
      setZIndex(name);
      setActiveCell(name);
    }
  };

  const renderContent = () => {
    switch (activeCell) {
      case "projects":
        return <MansoryGrid collectionName="projects" />;
      case "work-experience":
        return <List collectionName="work-experiences" />;
      case "education":
        return <p>Education</p>;
      case "contact":
        return <p>Contact form</p>;
      case "skills":
        return <p>Skill list</p>;
      default:
        return null;
    }
  };

  return (
    <div className="bento-grid">
      {Object.keys(tileNames).map((key, index) => (
        <motion.div
          key={index}
          className={`tile ${activeCell === key ? "active" : ""} ${key}`}
          onClick={() => handleCellClick(key)}
          onMouseEnter={() => setHoveredCell(key)}
          onMouseLeave={() => setHoveredCell(null)}
          layout
          transition={{
            layout: { type: "spring", bounce: 0.2, visualDuration: 0.5 },
          }}

          whileHover={{
            scale: 1.01,
            transition: { duration: 0.2 },
          }}

          style={
            activeCell === key
              ? {
                padding: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: zIndex === key ? 10 : 1,
              }
              : {}
          }
        >
          <AnimatePresence mode="popLayout">
            <motion.h2 layout='position' className="title">{tileNames[key]}</motion.h2>

            {key === "resume" && hoveredCell === "resume" && (
              <motion.div
                key="button"
                initial={{ opacity: 0, x: "-50%" }}
                animate={{ opacity: 1, x: "0%" }}
                exit={{ opacity: 0, x: "-50%" }}
                transition={{ duration: 0.3 }}
              >
                <motion.div whileHover={{
                  x: 5,
                  y: 5
                }}>
                  <a
                    href="/cv.pdf"
                    download="Satikit_Tapbumrong_CV.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download-button"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    Download
                  </a>
                </motion.div>

                <div className="black">
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {activeCell === key && renderContent()}
        </motion.div>
      ))}

      <div className="tile about">
        <h2>Satikit Tapbumrong</h2>
        <p>Software engineer student at KMITL</p>
        <p>Website under development</p>
      </div>

    </div>
  );
};

export default Home;
