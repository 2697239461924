import React, { useState, useEffect } from "react";
import { db } from "../../configuration";
import { collection, getDocs } from "firebase/firestore";
import { motion } from "framer-motion";
import "./list.css";

const List = ({ collectionName }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i) => {
            const delay = i * 0.3;
            return {
                pathLength: 1,
                opacity: 1,
                transition: {
                    pathLength: { delay, type: "spring", duration: 0.5, bounce: 0 },
                    opacity: { delay, duration: 0.01 },
                },
            };
        },
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, collectionName));
                const data = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setItems(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [collectionName]);

    if (loading) {
        return <div>Loading...</div>;
    }

    let cumulativeLeft = 13;
    const positions = items.map((item) => {
        const currentLeft = cumulativeLeft;
        const itemWidth = item.work.length * 10 + 10;
        cumulativeLeft += itemWidth;
        return { id: item.id, left: currentLeft };
    });
    const totalWidth = Math.max(window.innerWidth, items.length * 350 + window.innerWidth);
    console.log(totalWidth);
    cumulativeLeft = 5;
    let previousItem = 0;

    return (
        <div className="list">
            <motion.svg
                initial="hidden"
                animate="visible"
            >
                <motion.line
                    x1="0"
                    y1="50%"
                    x2={totalWidth}
                    y2="50%"
                    stroke="#000000"
                    variants={draw}
                    custom={0}
                />
                {items.map((item, i) => {
                    const position = positions.find((pos) => pos.id === item.id).left;
                    return item.work.map((subitem, index) => {
                        const lineIndex = previousItem;
                        previousItem += 1;
                        return (
                            <motion.line
                                key={`${item.id}-${index}`}
                                x1={`${position + index * 10}rem`}
                                y1="50%"
                                x2={`${position + index * 10}rem`}
                                y2={`${50 - ((-1) ** index) * 10}%`}
                                stroke="#000000"
                                variants={draw}
                                custom={lineIndex + 2}
                            />
                        );
                    });
                })}
            </motion.svg>

            {items.map((item, i) => {
                const currentLeft = cumulativeLeft;
                const itemWidth = item.work.length * 10 + 10;
                cumulativeLeft += itemWidth;
                const firstLineIndex = previousItem;

                return (
                    <div key={item.id} >
                        <motion.h1
                            style={{
                                position: "absolute",
                                left: `${currentLeft}rem`,
                                bottom: "47%",
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{
                                delay: firstLineIndex * 0.25 * i,
                                duration: 0.5,
                            }}
                        >
                            {item.id}
                        </motion.h1>
                        {item.work.map((subitem, index) => {
                            const lineIndex = previousItem;
                            previousItem += 1;
                            return (
                                <motion.div
                                    key={subitem.name}
                                    className="experience"
                                    style={{
                                        position: "absolute",
                                        left: `${currentLeft + index * 10}rem`,
                                        top: (1 + index) % 2 === 0 ? "60%" : "auto",
                                        bottom: (1 + index) % 2 !== 0 ? "60%" : "auto",
                                    }}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        delay: (lineIndex) * 0.3,
                                        duration: 0.5,
                                    }}
                                >
                                    <h3>{subitem.name}</h3>
                                    <h4>{subitem.duration}</h4>
                                    <p>{subitem.detail}</p>
                                </motion.div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

export default List;
